var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "member_name",
          align: "center",
          "min-width": "120",
          label: "用户姓名",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.member_name || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "remark", align: "center", label: "用户姓名备注" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入用户备注", maxlength: "15" },
                  on: {
                    blur: function ($event) {
                      return _vm.changeRemark(scope.row.remark, scope.row)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.changeRemark(scope.row.remark, scope.row)
                    },
                  },
                  model: {
                    value: scope.row.remark,
                    callback: function ($$v) {
                      _vm.$set(scope.row, "remark", $$v)
                    },
                    expression: "scope.row.remark",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "member_mobile", align: "center", label: "用户电话" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.member_mobile || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "delivery.name", align: "center", label: "自提点" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.delivery && scope.row.delivery.name
                        ? scope.row.delivery.name
                        : "-"
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "region",
          align: "center",
          label: "分区",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择分区" },
                    on: {
                      change: (e) => {
                        _vm.changeRegion(e, scope.row)
                      },
                    },
                    model: {
                      value: scope.row.region,
                      callback: function ($$v) {
                        _vm.$set(scope.row, "region", $$v)
                      },
                      expression: "scope.row.region",
                    },
                  },
                  _vm._l(_vm.regionList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "no",
          align: "center",
          label: "设置坑位号",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-input", {
                  attrs: { placeholder: "请输入坑位号" },
                  on: {
                    input: function ($event) {
                      scope.row.no = scope.row.no.replace(/[^\d]/g, "")
                    },
                    blur: () => _vm.changePit(scope.row, 1),
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return (() => _vm.changePit(scope.row, 1)).apply(
                        null,
                        arguments
                      )
                    },
                  },
                  model: {
                    value: scope.row.no,
                    callback: function ($$v) {
                      _vm.$set(scope.row, "no", $$v)
                    },
                    expression: "scope.row.no",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "qty",
          align: "center",
          label: "购买件数",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.qty || "-"))]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }