var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Receiving bj" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.setDefault } },
            [_vm._v("设置默认分货方式")]
          ),
          _c(
            "div",
            { staticClass: "seach" },
            [
              _c(
                "div",
                { staticClass: "itemInput1" },
                [
                  _vm._v(" 供货日期： "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择供货日期",
                      clearable: false,
                    },
                    model: {
                      value: _vm.from.supply_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "supply_time", $$v)
                      },
                      expression: "from.supply_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "itemInput" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入客户姓名", clearable: "" },
                    model: {
                      value: _vm.from.member_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "member_name", $$v)
                      },
                      expression: "from.member_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "itemInput" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入客户电话", clearable: "" },
                    model: {
                      value: _vm.from.member_mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "member_mobile", $$v)
                      },
                      expression: "from.member_mobile",
                    },
                  }),
                ],
                1
              ),
              _vm.role == "tdc"
                ? _c(
                    "div",
                    {
                      staticClass: "itemInput",
                      staticStyle: { "margin-right": "5px" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择自提点",
                          },
                          model: {
                            value: _vm.from.delivery_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.from, "delivery_id", $$v)
                            },
                            expression: "from.delivery_id",
                          },
                        },
                        _vm._l(_vm.arrList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "itemInput",
                  staticStyle: { "margin-right": "5px" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择分区" },
                      model: {
                        value: _vm.from.region,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "region", $$v)
                        },
                        expression: "from.region",
                      },
                    },
                    _vm._l(_vm.regionList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "itemInput" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入用户备注", clearable: "" },
                    model: {
                      value: _vm.from.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "remark", $$v)
                      },
                      expression: "from.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "btnWrap",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.search },
                },
                [_vm._v(" 查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btnWrap",
                  attrs: {
                    type: "warning",
                    icon: "el-icon-upload2",
                    disabled: !_vm.tableData.length || _vm.disabledExport,
                  },
                  on: { click: _vm.exportHandle },
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btnWrap",
                  attrs: { type: "primary" },
                  on: { click: _vm.resettingHandle },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              tableData: _vm.tableData,
              time: _vm.from.supply_time,
              regionList: _vm.regionList,
            },
            on: { submit: _vm.submit },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.from.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("print-set", {
        ref: "printSetRef",
        attrs: { dialogVisible: _vm.dialogVisible, showSetButton: false },
        on: {
          close: function ($event) {
            _vm.dialogVisible = false
          },
          submitHandle: _vm.submitHandle,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }