<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="member_name"
      align="center"
      min-width="120"
      label="用户姓名"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.member_name || "-" }}
      </template>
    </el-table-column>
    <el-table-column prop="remark" align="center" label="用户姓名备注">
      <template slot-scope="scope">
        <el-input
          v-model="scope.row.remark"
          placeholder="请输入用户备注"
          @keyup.enter.native="changeRemark(scope.row.remark, scope.row)"
          @blur="changeRemark(scope.row.remark, scope.row)"
          maxlength="15"
        ></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="member_mobile" align="center" label="用户电话">
      <template slot-scope="scope">
        {{ scope.row.member_mobile || "-" }}
      </template>
    </el-table-column>
    <el-table-column prop="delivery.name" align="center" label="自提点">
      <template slot-scope="scope">
        {{
          scope.row.delivery && scope.row.delivery.name
            ? scope.row.delivery.name
            : "-"
        }}
      </template>
    </el-table-column>
    <el-table-column
      prop="region"
      align="center"
      label="分区"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <el-select
          v-model="scope.row.region"
          clearable
          placeholder="请选择分区"
          @change="
            (e) => {
              changeRegion(e, scope.row);
            }
          "
        >
          <el-option
            :key="item"
            v-for="item in regionList"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column
      prop="no"
      align="center"
      label="设置坑位号"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <el-input
          v-model="scope.row.no"
          placeholder="请输入坑位号"
          @input="scope.row.no = scope.row.no.replace(/[^\d]/g, '')"
          @keyup.enter.native="() => changePit(scope.row, 1)"
          @blur="() => changePit(scope.row, 1)"
        ></el-input>
      </template>
    </el-table-column>
    <el-table-column
      prop="qty"
      align="center"
      label="购买件数"
      show-overflow-tooltip
    >
      <template slot-scope="scope"> {{ scope.row.qty || "-" }}</template>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  postTakeOrderPlacenoSet,
  postTakeOrderPlacenoRemarkSave,
} from "@/api/customPit.js";
export default {
  name: "TableList",
  props: ["tableData", "time", "regionList"],
  data() {
    return {};
  },
  methods: {
    /**
     * 修改分区
     */
    changeRegion(e, item) {
      console.log(e, item, "e===");
      if (!item.no) {
        this.$message.error("请先设置坑位号");
        item.region = "";
        return;
      }
      this.changePit(item, 2);
    },
    /**
     * 修改坑位号
     */
    async changePit(item, type) {
      console.log(item, "修改坑位号");
      try {
        const res = await postTakeOrderPlacenoSet({
          number: item.no,
          id: item.id,
          supply_time: this.time,
          region: item.region,
        });
        if (res.data.alert) {
          this.$message.success(res.data.alert);
        } else {
          this.$message.success(type == 2 ? "修改分区成功" : "修改坑位成功");
        }
        this.$emit("submit");
      } catch (error) {
        console.log(error, "postTakeOrderPlacenoSet");
      }
    },
    /**
     * 修改用户姓名备注
     */
    async changeRemark(e, item) {
      console.log(e, item, "修改用户姓名备注");
      try {
        const res = await postTakeOrderPlacenoRemarkSave({
          remark: e,
          id: item.id,
        });
        this.$message.success("修改备注成功");
        this.$emit("submit");
      } catch (error) {
        console.log(error, "postTakeOrderPlacenoRemarkSave");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
