<template>
  <div class="Receiving bj">
    <div class="title">
      <!-- <div class="d-flex a-center"> -->
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <el-button type="primary" @click="setDefault">设置默认分货方式</el-button>
      <!-- </div> -->
      <div class="seach">
        <div class="itemInput1">
          供货日期：
          <el-date-picker
            
            v-model="from.supply_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择供货日期"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="itemInput">
          <el-input
            placeholder="输入客户姓名"
            v-model="from.member_name"
            clearable
          ></el-input>
        </div>
        <div class="itemInput">
          <el-input
            placeholder="输入客户电话"
            v-model="from.member_mobile"
            clearable
          ></el-input>
        </div>
        <div class="itemInput" v-if="role == 'tdc'" style="margin-right: 5px">
          <el-select
            v-model="from.delivery_id"
            filterable
            clearable
            placeholder="请选择自提点"
          >
            <el-option
              :key="item.id"
              v-for="item in arrList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="itemInput" style="margin-right: 5px">
          <el-select v-model="from.region" clearable placeholder="请选择分区">
            <el-option
              :key="item"
              v-for="item in regionList"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>

        <div class="itemInput">
          <el-input
            placeholder="输入用户备注"
            v-model="from.remark"
            clearable
          ></el-input>
        </div>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="search"
          class="btnWrap"
        >
          查询
        </el-button>
        <el-button
          class="btnWrap"
          type="warning"
          icon="el-icon-upload2"
          @click="exportHandle"
          :disabled="!tableData.length || disabledExport"
          >导出</el-button
        >
        <el-button type="primary" @click="resettingHandle" class="btnWrap"
          >重置</el-button
        >
      </div>
    </div>
    <!-- 列表 start -->
    <div class="auto-table-flex">
      <table-list
        v-loading="loading"
        :tableData="tableData"
        :time="from.supply_time"
        :regionList="regionList"
        @submit="submit"
      ></table-list>
    </div>
    <!-- 列表 end -->

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="from.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增打印坑位自定义弹窗 start -->
    <print-set
      :dialogVisible="dialogVisible"
      @close="dialogVisible = false"
      @submitHandle="submitHandle"
      :showSetButton="false"
      ref="printSetRef"
    />
    <!-- 新增打印坑位自定义弹窗 end -->
  </div>
</template>
<script>
import TableList from "./modules/table-list/index";
import { formatDate } from "@/utils/tools/date.js";
import cloneDeep from "lodash/cloneDeep";
import {
  postTakeOrderPlacenoList,
  getTakeOrderPlacenoExport,
  postTakeOrderPlacenoReset,
  postTakeOrderPlacenoRegion,
} from "@/api/customPit.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import printSet from "../modules/printSet.vue";

export default {
  name: "CustomPitLocation",
  components: { TableList, printSet },
  data() {
    return {
      dialogVisible: false,
      disabledExport: false,
      loading: false,
      role: sessionStorage.getItem("manageIsdentity"),
      from: {
        region: "", // 选择分区
        remark: "", // 用户姓名备注
        delivery_id: "", //用户自提点ID
        member_name: "", //用户名称
        member_mobile: "", // 用户电话
        supply_time: formatDate(), // 供货时间
        page: 1,
        pageSize: 100,
      },
      formDataClone: {}, // 复制一份 初始化数据
      tableData: [],
      arrList: [],
      total: 0,
      regionList: [], // 分区列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.from);
    // 城市仓 角色 请求自提点列表
    if (this.role != "delivery") {
      this.getDeliveryList();
    }
    this.postAjaxTakeOrderPlacenoRegion();
    this.hqlist();
  },
  methods: {
    // 配置打印类型成功 刷新列表
    submitHandle() {
      this.from.page = 1;
      this.tableData = [];
      this.hqlist();
    },
    // 设置默认分货方式
    setDefault() {
      this.dialogVisible = true;
      this.$refs.printSetRef.onInitData(1);
    },
    /**
     * 获取分区列表
     */
    async postAjaxTakeOrderPlacenoRegion() {
      try {
        const res = await postTakeOrderPlacenoRegion();
        this.regionList = res.data;
      } catch (error) {
        console.log(error, "postTakeOrderPlacenoRegion");
      }
    },
    /* 获取自提点列表 */
    async getDeliveryList() {
      try {
        const res = await this.$api.delivery.deliverylist();
        this.arrList = res.data;
      } catch (error) {
        console.log(error, "deliverylist===");
      }
    },
    /* 搜索 */
    search() {
      this.from.page = 1;
      this.tableData = [];
      this.hqlist();
    },
    /* 导出 */
    async exportHandle() {
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.from,
        };
        delete params.page;
        delete params.pageSize;
        const res = await getTakeOrderPlacenoExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getTakeOrderPlacenoExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /* 重置 */
    async resettingHandle() {
      try {
        const res = await postTakeOrderPlacenoReset();
        this.from.page = 1;
        this.tableData = [];
        this.hqlist();
      } catch (error) {
        console.log(error, "postTakeOrderPlacenoReset");
      }
    },
    Refresh() {
      this.from = {
        ...this.formDataClone,
      };
      this.from.page = 1;
      this.tableData = [];
      this.hqlist();
    },
    /** 页码发生改变 */
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.hqlist();
    },
    /** 页数发生改变 */
    handleCurrentChange(val) {
      this.from.page = val;
      this.hqlist();
    },
    /** 修改备注 ｜坑位 成功 刷新列表 */
    submit() {
      this.from.page = 1;
      this.tableData = [];
      this.hqlist();
    },
    /** 列表数据 */
    async hqlist() {
      this.loading = true;
      let params = this.tool.DeepCopy({}, this.from);
      if (this.role == "delivery" && params.hasOwnProperty("delivery_id")) {
        delete params.delivery_id;
      }
      try {
        const res = await postTakeOrderPlacenoList(params);
        this.tableData = res.data.list;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postTakeOrderPlacenoList");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Receiving {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  /deep/ .el-table .el-table__cell {
    padding: 5px 0px !important;
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .itemInput {
        width: 150px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .itemInput1 {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .btnWrap {
        margin-bottom: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
